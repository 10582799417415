@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.blur {
    filter: blur(4px);
    -webkit-filter: blur(4px);
}

.backdrop-filter {
    backdrop-filter: blur(4px);
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */

.no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.flip-vertical {
    --transform-scale-y: -1;
}

.toast-enter {
    transform: translateX(100%);
    opacity: 0;
}

.toast-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: all 300ms ease-in;
}

.toast-exit {
    opacity: 1;
    transform: translateX(0);
}

.toast-exit-active {
    opacity: 0;
    transform: translateX(100%);
    transition: all 300ms ease-in;
}